.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tabsContainer{
  /* .MuiButtonBase-root {
    font-size: 2em;
    min-width: 10px;
    font-weight: 700;
    margin-left: 10px;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.7;
  } */
  .MuiTab-root {
    font-size: 1.2vw;
    min-width: 10px;
    font-weight: 700;
    margin-left: 10px;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.7;
    font-family: Poppins !important;
  }
}

.items {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1.4em;
}
.heading {
  padding-top: 50px;
  margin-bottom: 10px;
  color: white;
}
